import { useState, useEffect } from "react";

export const useEdge = () => {
  const [edge, setEdge] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const height = window.innerHeight;
      const width = window.innerWidth;
      const newEdge = height <= width ? "100vh" : "100vw";

      if (newEdge !== edge) {
        setEdge(newEdge); // Only set state if different from current
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set the initial value on mount

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [edge]);

  return edge;
};
