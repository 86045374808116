import React, { useRef, useState, useEffect, useCallback } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { useAnimationContext } from "../context/AnimationContext";
import "../styles/menu.css";

export default function Menu(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const isAnimating = useRef(false);
  const buttonRef = useRef(null);
  const overlayRef = useRef(null);
  const { contextSafe } = useGSAP({ scope: overlayRef });
  const { handleTransition } = useAnimationContext();

  const linkMap = [
    {
      anchorProps: {
        href: "/about",
      },
      label: "About",
    },
    {
      anchorProps: {
        href: "/work",
      },
      label: "Work",
    },
    {
      anchorProps: {
        href: "/Gustaf_Buchanan_Resume.pdf",
        target: "_blank",
        rel: "noopener noreferrer",
      },
      label: "Resume",
    },

    {
      anchorProps: {
        href: "/contact",
      },
      label: "Contact",
    },
    {
      anchorProps: {
        href: "/",
      },
      label: "Home",
    },
  ];

  const timelineCallback = {
    onStart: () => {
      isAnimating.current = true;
    },
    onComplete: () => {
      isAnimating.current = false;
    },
  };

  const animateIn = contextSafe(() => {
    const duration = 0.15;
    const tl = gsap.timeline(timelineCallback);
    tl.to(buttonRef.current, { duration, autoAlpha: 0 }, 0);
    tl.to(
      "#menu_overlay_container, #overlay_wrapper",
      { duration, autoAlpha: 1 },
      ">"
    );
    tl.fromTo(
      "a",
      { y: -17 },
      {
        duration,
        y: 0,
        stagger: 0.07,
        autoAlpha: 1,
      },
      ">+=.05"
    );
  });

  const animateOut = contextSafe((e) => {
    const duration = 0.15;
    const tl = gsap.timeline(timelineCallback);
    tl.fromTo(
      "a",
      { y: 0 },
      {
        duration,
        y: -17,
        autoAlpha: 0,
        stagger: {
          each: 0.07,
          from: "end",
        },
      },
      0
    );
    tl.to(
      "#menu_overlay_container, #overlay_wrapper",
      { duration, autoAlpha: 0 },
      ">"
    );
    tl.to(
      buttonRef.current,
      {
        duration,
        autoAlpha: e ? 0 : 1,
        onComplete: () => {
          setMenuOpen(false);
          if (e) handleTransition(e);
        },
      },
      ">"
    );
  });

  const toggle = useCallback(() => {
    if (isAnimating.current) return;
    if (!menuOpen) {
      setMenuOpen(true);
    } else {
      animateOut();
    }
  }, [animateOut, setMenuOpen, menuOpen]);

  useEffect(() => {
    if (!menuOpen) return;
    animateIn();
  }, [menuOpen, animateIn]);

  useEffect(() => {
    if (menuOpen) {
      const tabIndexes = [];
      const overlayElement = overlayRef.current;
      //add any focusable HTML element you want to include to this string
      const focusableElements = overlayElement.querySelectorAll(
        'a, [tabindex]:not([tabindex="-1"])'
      );
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      const unfocusableElements = document.querySelectorAll(".footer_link");
      unfocusableElements.forEach((el) => {
        if (el.tabIndex) {
          tabIndexes.push({ element: el, tabIndex: el.tabIndex });
          el.tabIndex = -1;
        }
      });

      const handleTabKeyPress = (event) => {
        if (event.key === "Tab") {
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          } else if (
            !event.shiftKey &&
            document.activeElement === lastElement
          ) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      };

      const handleEscapeKeyPress = (event) => {
        if (event.key === "Escape") {
          toggle();
        }
      };

      overlayElement.addEventListener("keydown", handleTabKeyPress);
      overlayElement.addEventListener("keydown", handleEscapeKeyPress);

      return () => {
        overlayElement.removeEventListener("keydown", handleTabKeyPress);
        overlayElement.removeEventListener("keydown", handleEscapeKeyPress);
        tabIndexes.forEach(({ element, tabIndex }) => {
          element.tabIndex = tabIndex;
        });
      };
    }
  }, [menuOpen, toggle]);

  const HoverAnimation = ({ children }) => {
    const wrapperRef = useRef(null);
    const { contextSafe } = useGSAP({ scope: wrapperRef });
    const duration = 0.5;

    const handleMouseEnter = contextSafe(() => {
      gsap.to(".menu_link", {
        duration,
        ease: "Power4.easeOut",
        color: "#D62732",
      });
    });

    const handleMouseLeave = contextSafe(() => {
      gsap.to(".menu_link", {
        duration,
        ease: "Power4.easeOut",
        color: "#fffcd7",
      });
    });

    return (
      <span
        ref={wrapperRef}
        onFocus={handleMouseEnter}
        onBlur={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </span>
    );
  };

  return (
    <>
      <button
        tabIndex="1"
        id="menu_button"
        ref={buttonRef}
        aria-expanded={menuOpen}
        aria-label="Menu butotn"
        onClick={toggle}
      >
        <div className="menu_line" id="menu_top" />
        <div className="menu_line" id="menu_middle" />
        <div className="menu_line" id="menu_bottom" />
      </button>
      {menuOpen ? (
        <div id="menu-overlay" ref={overlayRef}>
          <div onClick={toggle} id="menu_overlay_container">
            <div id="overlay_wrapper">
              <div id="menu_overlay" />
            </div>
            <menu id="menu_options_container">
              {linkMap.map((link, i) => {
                return (
                  <a
                    key={link.label}
                    {...link.anchorProps}
                    onClick={(e) => {
                      if (link.label === "Resume") return;
                      if (isAnimating.current) return;
                      e.preventDefault();
                      e.stopPropagation();
                      const fakeEvent = {
                        href: e.currentTarget.getAttribute("href"),
                      };
                      animateOut(fakeEvent);
                    }}
                  >
                    <HoverAnimation>
                      <h1 className="menu_link">{link.label}</h1>
                    </HoverAnimation>
                  </a>
                );
              })}
            </menu>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
