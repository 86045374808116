import React, {
  createContext,
  useEffect,
  useContext,
  useRef,
  useState,
} from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { navigate } from "gatsby";
import usePathTracking from "../hooks/usePathTracking";
import { useEdge } from "../hooks/useEdge";
const AnimationContext = createContext();

const transitionMap = {
  "/about": {
    color: "#017BB3",
  },
  "/work": {
    color: "#EFA226",
  },
  "/contact": {
    color: "#fffcd7",
  },
  "/": {
    color: "#000000",
  },
};

export const AnimationProvider = ({ children }) => {
  const [homeLoaded, setHomeLoaded] = useState(false);
  const [edgeReady, setEdgeReady] = useState(false);
  const [eyeReady, setEyeReady] = useState(false);
  const edge = useEdge();
  const { currentPath, prevPath } = usePathTracking();
  const [color, setColor] = useState(
    currentPath in transitionMap ? transitionMap[currentPath].color : null
  );
  const scopeRef = useRef(null);
  const { contextSafe } = useGSAP({ scope: scopeRef });
  const recordContainer = "#record_container";
  const record = "#record";
  const recordBack = "#record_back";
  const clickShield = "#click_shield";
  const home = "/";
  const contact = "/contact";
  const isHome = currentPath === "/";
  const isContact = currentPath === "/contact";

  const updateEdge = contextSafe(() => {
    gsap.set(recordContainer, { height: edge, width: edge });
  });

  useEffect(() => {
    if (homeLoaded && isHome) {
      updateEdge();
    }
    if (edgeReady && isContact) {
      updateEdge();
    }
  }, [edge]);

  useEffect(() => {
    if (edge === null) return;
    setEdgeReady(true);
  }, [edge]);

  const handleMenuVisibility = contextSafe((showMenu) => {
    const duration = 0.25;
    const autoAlpha = showMenu ? 1 : 0;
    gsap.to("#menu_button", { duration, autoAlpha });
  });

  const toggleClickShield = contextSafe((turnOn) => {
    if (turnOn) {
      gsap.set(clickShield, { display: "block" });
    } else {
      gsap.set(clickShield, { display: "none" });
    }
  });

  const animationStartCallback = {
    onStart: () => toggleClickShield(true),
  };

  const handleHome = contextSafe(() => {
    const duration = 0.5;
    const load = gsap.timeline(animationStartCallback);
    load.to(
      [record, recordContainer],
      {
        ease: "Power1.easeOut",
        duration,
        height: edge,
        width: edge,
        scale: 0.75,
      },
      0.5
    );
    load.to(
      record,
      { ease: "Power1.easeOut", duration, rotateY: "+=180deg" },
      "-=.25"
    );

    load.to(
      [recordContainer, record],
      {
        ease: "Power1.easeOut",
        duration,
        scale: 1,
        onComplete: () => {
          gsap.set(record, { height: "100%", width: "100%" });
          toggleClickShield(false);
          handleMenuVisibility(true);
          if (isHome) setEyeReady(true);
        },
      },
      "=0"
    );
  });

  const afterFlip = contextSafe(() => {
    updateEdge();
    handleMenuVisibility(true);
    gsap.set(record, { rotateY: "+=180deg" });
    if (isHome) setEyeReady(true);
  });

  const flip = contextSafe((destination) => {
    const duration = 0.5;
    const flip = gsap.timeline(animationStartCallback);

    flip.to(record, {
      ease: "Power1.easeOut",
      duration,
      rotateY: "+=180deg",
      onComplete: () => {
        toggleClickShield(false);
        navigate(destination);
      },
    });
  });

  const updateColor = contextSafe((uri) => {
    if (transitionMap[uri]) setColor(transitionMap[uri].color);
  });

  const leaveHome = contextSafe((destination) => {
    const duration = 0.5;
    const tl = gsap.timeline(animationStartCallback);
    gsap.set(recordBack, { backgroundColor: color.current });
    tl.to(record, { duration, ease: "Power1.easeOut", scale: 0.75 })
      .to(
        record,
        { duration, ease: "Power1.easeOut", rotateY: "+=180deg" },
        "-=.25"
      )
      .to(
        [record, recordContainer],
        {
          duration,
          ease: "Power1.easeOut",
          scale: 1,
          height: "100%",
          width: "100%",
          onComplete: () => {
            toggleClickShield(false);
            navigate(destination);
          },
        },
        "=0"
      );
  });

  const handleTransition = contextSafe((e) => {
    const destination = e.href || e.currentTarget.getAttribute("href");

    if (currentPath === home) {
      if (destination === home) {
        handleMenuVisibility(true);
        navigate(destination);
      } else {
        setEyeReady(false);
        handleMenuVisibility(false);
        updateColor(destination);
        if (destination === contact) {
          flip(destination);
        } else {
          leaveHome(destination);
        }
      }
    } else if (currentPath === contact) {
      if (destination === home) {
        updateColor(destination);
        handleMenuVisibility(false);
        flip(destination);
      } else {
        if (destination === contact) {
          handleMenuVisibility(true);
          navigate(destination);
        } else {
          updateColor(destination);
          leaveHome(destination);
        }
      }
    } else {
      if (destination !== home && destination !== contact) {
        updateColor(destination);
      }
      if (destination === currentPath) {
        handleMenuVisibility(true);
      } else {
        handleMenuVisibility(false);
      }
      navigate(destination);
    }
  });

  return (
    <AnimationContext.Provider
      value={{
        handleTransition,
        handleHome,
        updateColor,
        color,
        handleMenuVisibility,
        homeLoaded,
        setHomeLoaded,
        eyeReady,
        isHome,
        setEdgeReady,
        edgeReady,
        currentPath,
        prevPath,
        afterFlip,
        updateEdge,
        edge,
      }}
    >
      <div ref={scopeRef}>{children}</div>
    </AnimationContext.Provider>
  );
};

export const useAnimationContext = () => useContext(AnimationContext);
