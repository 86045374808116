import React, { useEffect, useState } from "react";
import Menu from "./Menu";
import { useAnimationContext } from "../context/AnimationContext";

export default function Layout({ children, uri }) {
  const { updateColor, handleMenuVisibility } = useAnimationContext();

  const [appMinHeight, setAppMinHeight] = useState("100vh");

  useEffect(() => {
    if (uri === "/" || uri === "/contact") return;
    handleMenuVisibility(true);
  }, [uri]);

  useEffect(() => {
    if (uri === "/" || uri === "/contact") return;
    updateColor(uri);
  }, [uri]);

  useEffect(() => {
    if (uri !== "/") {
      setAppMinHeight("100vh");
    } else {
      const updateHeight = () => {
        setAppMinHeight(`${window.innerHeight}px`);
      };
      updateHeight();
      window.addEventListener("resize", updateHeight);
      return () => {
        window.removeEventListener("resize", updateHeight);
      };
    }
  }, [uri]);

  return (
    <div
      id="app"
      className="app"
      style={{
        minHeight: appMinHeight,
      }}
    >
      <div id="click_shield" />
      <Menu />
      {children}
    </div>
  );
}
