// import React, { useState, useEffect } from "react";
// import { globalHistory } from "@reach/router";

// function usePathTracking() {
//   const [currentPath, setCurrentPath] = useState(
//     globalHistory.location.pathname
//   );
//   const [prevPath, setPrevPath] = useState(null);

//   useEffect(() => {
//     const unlisten = globalHistory.listen(({ location }) => {
//       setPrevPath(currentPath); // Use the currentPath here directly
//       setCurrentPath(location.pathname);
//     });

//     return () => unlisten();
//   }, [currentPath]); // Ensure currentPath is a dependency

//   return { currentPath, prevPath };
// }

// export default usePathTracking;

import React, { useState, useEffect } from "react";
import { globalHistory } from "@reach/router";

function usePathTracking() {
  // const formatPath = (path) => path.replace(/\/$/, ""); // Remove trailing slash, if present

  const formatPath = (path) => {
    if (path === "/") return path;
    return path.replace(/\/$/, "");
  };

  const [currentPath, setCurrentPath] = useState(
    formatPath(globalHistory.location.pathname)
  );
  const [prevPath, setPrevPath] = useState(null);

  useEffect(() => {
    const unlisten = globalHistory.listen(({ location }) => {
      setPrevPath(currentPath); // Use the currentPath here directly
      setCurrentPath(formatPath(location.pathname)); // Format new path
    });

    return () => unlisten();
  }, [currentPath]); // Ensure currentPath is a dependency

  return { currentPath, prevPath };
}

export default usePathTracking;
