import "./src/styles/global.css";
import React from "react";
import Layout from "./src/components/layout";
import { AnimationProvider } from "./src/context/AnimationContext";

export const wrapRootElement = ({ element }) => (
  <AnimationProvider>{element}</AnimationProvider>
);

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
